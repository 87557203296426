<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar />
          </div>
          <div class="w-full tablet:w-3/4">
            <product-detail :data="activePart" />
            <div
              v-if="alternativeProducts.length > 0"
              class="alternative_products mt-20"
            >
              <header-h5
                :content="$t('components.products.alternative')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div class="flex flex-wrap">
                <div
                  v-for="product in alternativeProducts"
                  class="w-full tablet:w-1/3 px-4"
                >
                  <product-quick
                    :data="product"
                    :link="{
                      name: 'product-product',
                      params: {
                        product: $slugify(product.link)
                      }
                    }"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="spareProducts.length > 0"
              class="alternative_products mt-20"
            >
              <header-h5
                :content="$t('components.products.spare_parts')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div class="flex flex-wrap">
                <div
                  v-for="product in spareProducts"
                  class="w-full tablet:w-1/3 px-4"
                >
                  <product-quick
                    :data="product"
                    :link="{
                      name: 'product-product',
                      params: {
                        product: $slugify(product.link)
                      }
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs'
import portal from '../../service/portal'
import ProductDetail from '../../components/products/Detail'
import Sidebar from '../../components/bars/Sidebar'
import ProductQuick from '../../components/products/Quick'
export default {
  name: 'Product',
  components: {
    ProductQuick,
    Sidebar,
    ProductDetail,
    BarBreadcrumbs
  },

  head() {
    const title =
      this.activePart.product.name +
      (!this.activePart.to_be_anounced ? ' - ' + this.activePart.sku : '') +
      ' - Air Conditioning Parts - AP Air Ltd.'
    const description = ''
    const og_image = this.activePart.images[0] ?? 'https://www.apairltd.com/images/placeholder.jpg'

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:image', content: og_image },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          innerHTML: JSON.stringify(this.structuredData),
          type: 'application/ld+json'
        }
      ]
    }
  },
  async asyncData({ app, params, store }) {
    let activePart = {}
    const productId = params.product.split('-')[0]
    const [productDetail] = await Promise.all([
      portal.get(`products/${productId}?lang=${store.state.language}`)
    ])
    const product = productDetail.data
    for (let j = 0; j < product.translations.length; j++) {
      if (product.translations[j].language.language_code === 'en') {
        product.fallback_product = product.translations[j]
      }
      if (
        product.translations[j].language.language_code === store.state.language
      ) {
        product.product = product.translations[j]
      }
    }
    activePart = product

    activePart.link =
      product.id + ' ' + product.sku + ' ' + product.product.name

    const alternativeProducts = []
    const spareProducts = []
    if (activePart.product_alternatives.length > 0) {
      for (let i = 0; i < activePart.product_alternatives.length; i++) {
        const product = activePart.product_alternatives[i]

        for (
          let j = 0;
          j < activePart.product_alternatives[i].translations.length;
          j++
        ) {
          if (
            activePart.product_alternatives[i].translations[j].language
              .language_code === 'en'
          ) {
            product.fallback_product =
              activePart.product_alternatives[i].translations[j]
          }
          if (
            activePart.product_alternatives[i].translations[j].language
              .language_code === store.state.language
          ) {
            product.product = activePart.product_alternatives[i].translations[j]
          }
        }
        product.link =
          product.id + ' ' + product.sku + ' ' + product.product.name
        alternativeProducts.push(product)
      }
    }
    if (activePart.product_spareparts.length > 0) {
      for (let i = 0; i < activePart.product_spareparts.length; i++) {
        const product = activePart.product_spareparts[i]

        for (
          let j = 0;
          j < activePart.product_spareparts[i].translations.length;
          j++
        ) {
          if (
            activePart.product_spareparts[i].translations[j].language
              .language_code === 'en'
          ) {
            product.fallback_product =
              activePart.product_spareparts[i].translations[j]
          }
          if (
            activePart.product_spareparts[i].translations[j].language
              .language_code === store.state.language
          ) {
            product.product = activePart.product_spareparts[i].translations[j]
          }
        }
        product.link =
          product.id + ' ' + product.sku + ' ' + product.product.name
        spareProducts.push(product)
      }
    }

    const breadcrumbs = [
      {
        name: app.i18n.t('menu.home'),
        url: 'index'
      },
      {
        name: app.i18n.t('menu.products'),
        url: 'products'
      },
      {
        name: activePart.product.name,
        url: {
          name: 'product-product',
          params: {
            product: app.$slugify(activePart.link)
          }
        }
      }
    ]
    let description = activePart.product.description
    if (activePart.product.part_specification_1) {
      description =
        description + ' \n ' + activePart.product.part_specification_1
    }
    if (activePart.product.part_specification_2) {
      description =
        description + ' \n ' + activePart.product.part_specification_2
    }
    if (activePart.product.part_specification_3) {
      description =
        description + ' \n ' + activePart.product.part_specification_3
    }
    // const descriptionOems = []
    // activePart.product_vehicles.map((item) => {
    //   const tempOem = JSON.parse(item.showed_oems)
    //   for (let i = 0; i < tempOem.length; i++) {
    //     if (tempOem[i] !== '') {
    //       if (descriptionOems.includes(tempOem[i]) === false) {
    //         descriptionOems.push(tempOem[i])
    //       }
    //     }
    //   }
    // })
    // if (descriptionOems.length > 0) {
    //   description = description + ' \n ' + 'OEM: '
    //   let oemString = ''
    //   for (let i = 0; i < descriptionOems.length; i++) {
    //     oemString =
    //       oemString + (oemString === '' ? '' : ', ') + descriptionOems[i]
    //   }
    //   description = description + oemString
    // }

    return {
      breadcrumbs,
      activePart,
      spareProducts,
      alternativeProducts,
      productDetail: productDetail.data,
      structuredData: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: activePart.product.name,
        image:
          activePart.images ??
          'https://www.apairltd.com/images/placeholder.jpg',
        description,
        sku: activePart.to_be_announced ? 'TBA' : activePart.sku,
        mpn: activePart.to_be_announced ? 'TBA' : activePart.sku,
        brand: {
          '@type': 'Brand',
          name: 'AP Air LTD.'
        },
        offers: {
          '@type': 'Offer',
          availability: 'https://schema.org/InStock',
          price:
            activePart.price_object === null
              ? 0
              : JSON.parse(activePart.price_object.price_object).Pricelist16,
          priceCurrency: 'GBP'
        }
      }
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Product',
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"contact"}},[_c('bar-breadcrumbs',{attrs:{"data":_vm.breadcrumbs}}),_vm._v(" "),_c('section',{staticClass:"bg-gray-medium py-16"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full tablet:w-7/10 tablet:mx-auto px-4"},[_c('header-h2',{attrs:{"content":_vm.page.title,"color":"black","casing":"uppercase","family":"header","align":"center","mb":"4"}}),_vm._v(" "),_c('div',{staticClass:"form"},[_c('form-main',{attrs:{"form":{
                showLabels: true,
                showPlaceholders: true,
                requiredText: '*',
                buttonType: 'primary',
                buttonText: _vm.$t('form.send'),
                fields: [
                  {
                    type: 'textarea',
                    name: 'contact_message',
                    label: _vm.$t('form.return.cause'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'text',
                    name: 'contact_first_name',
                    label: _vm.$t('form.return.first_name'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'text',
                    name: 'contact_last_name',
                    label: _vm.$t('form.return.last_name'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'date',
                    name: 'contact_date',
                    label: _vm.$t('form.return.date'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'email',
                    name: 'contact_email',
                    label: _vm.$t('form.email'),
                    required: true
                  },
                  {
                    type: 'input',
                    subtype: 'tel',
                    name: 'contact_phone',
                    label: _vm.$t('form.phone'),
                    required: true
                  }
                ]
              }}}),_vm._v(" "),(_vm.message !== '')?_c('div',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e()],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
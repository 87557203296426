<template>
  <main id="contact">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bg-gray-medium py-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-7/10 tablet:mx-auto px-4">
            <header-h2
              :content="page.title"
              color="black"
              casing="uppercase"
              family="header"
              align="center"
              mb="4"
            />
            <div class="form">
              <form-main
                :form="{
                  showLabels: true,
                  showPlaceholders: true,
                  requiredText: '*',
                  buttonType: 'primary',
                  buttonText: $t('form.send'),
                  fields: [
                    {
                      type: 'textarea',
                      name: 'contact_message',
                      label: $t('form.return.cause'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'text',
                      name: 'contact_first_name',
                      label: $t('form.return.first_name'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'text',
                      name: 'contact_last_name',
                      label: $t('form.return.last_name'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'date',
                      name: 'contact_date',
                      label: $t('form.return.date'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'email',
                      name: 'contact_email',
                      label: $t('form.email'),
                      required: true
                    },
                    {
                      type: 'input',
                      subtype: 'tel',
                      name: 'contact_phone',
                      label: $t('form.phone'),
                      required: true
                    }
                  ]
                }"
              />
              <div v-if="message !== ''" v-html="message" class="mt-4" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../components/bars/Breadcrumbs'
import wordpress from '~/service/wordpress'
export default {
  name: 'ReturnPolicy',
  components: { BarBreadcrumbs },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Air Conditioning Parts - AP Air Ltd.'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  data() {
    return {
      message: '',
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.return_policy'),
          url: 'return-policy'
        }
      ]
    }
  },
  async asyncData({ app }) {
    const [page] = await Promise.all([
      wordpress.get(`page/${app.i18n.t('endpoints.return_policy')}`)
    ])

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    submitForm() {
      const params = new URLSearchParams()
      if (
        this.validateEmail(
          this.$el.getElementsByClassName('form_input')[4].value
        )
      ) {
        params.append(
          'contact_message',
          this.$el.getElementsByClassName('form_input')[0].value
        )
        params.append(
          'contact_first_name',
          this.$el.getElementsByClassName('form_input')[1].value
        )
        params.append(
          'contact_last_name',
          this.$el.getElementsByClassName('form_input')[2].value
        )
        params.append(
          'contact_date',
          this.$el.getElementsByClassName('form_input')[3].value
        )
        params.append(
          'contact_email',
          this.$el.getElementsByClassName('form_input')[4].value
        )
        params.append(
          'contact_phone',
          this.$el.getElementsByClassName('form_input')[5].value
        )
        this.$axios
          .post(this.$t('endpoints.return_form'), params, {
            'Content-Type': 'application/x-www-form-urlencoded'
          })
          .then(() => {
            this.message = this.$t('form.thank_you')
          })
          .catch(() => {
            this.message = this.$t('form.fail')
          })
      } else {
        this.message = this.$t('form.false_email')
      }
    }
  }
}
</script>

<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar :data="[{ activeGroup, brands }]" />
          </div>
          <div class="w-full tablet:w-3/4">
            <nuxt-link :to="localePath('products')">
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_1'" />
                <span
                  v-html="
                    activeGroup.name
                      ? activeGroup.name
                      : activeGroup.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-red text-white p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_2'" />
              </div>
            </nuxt-link>
            <section class="pb-8">
              <div class="container">
                <div class="flex flex-wrap">
                  <div class="w-full px-4">
                    <header-h5
                      :content="$t('components.search_box.filter')"
                      align="center"
                      casing="uppercase"
                      mt="0"
                      mb="2"
                    />
                    <div class="form_item">
                      <input
                        v-model="searchPart"
                        :placeholder="$t('components.search_box.search_part')"
                        @keyup="filterParts"
                        type="text"
                        class="form_input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="grid grid-cols-1 tablet:grid-cols-3 gap-5 laptop:gap-8 px-4 pb-8">
                <template v-for="brand in brands">
                  <nuxt-link
                    v-if="brand !== null"
                    class="category"
                    :to="
                      localePath({
                        name: 'products-group-brand',
                        params: {
                          group: $slugify(
                            activeGroup.name
                              ? activeGroup.name
                              : activeGroup.fallback_name
                          ),
                          brand: $slugify(
                            brand.name ? brand.name : brand.fallback_name
                          )
                        }
                      })
                    "
                  >
                    <img
                      :alt="brand.name ? brand.name : brand.fallback_name"
                      :src="
                        brand.images.length > 0
                          ? brand.images[0]
                          : '/images/placeholder-selector.jpg'
                      "
                      class="mx-auto"
                      style="max-height: 75px;"/>
                    <p class="text-center uppercase mt-2 mb-0">
                      <strong>
                        {{ brand.name ? brand.name : brand.fallback_name }}
                      </strong>
                    </p>
                  </nuxt-link>
                </template>
              </div>
            </section>
            <div class="bg-gray-dark text-white p-2 mb-4 mx-4">
              <strong v-t="'components.products.selector.step_3'" />
            </div>
            <div class="bg-gray-dark text-white p-2 mb-4 mx-4">
              <strong v-t="'components.products.selector.step_4'" />
            </div>

            <p v-html="$t('components.products.please-note')" class="px-4"></p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '/components/bars/Breadcrumbs';
import portal from '/service/portal';
import Sidebar from '/components/bars/Sidebar';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Products',
  components: { Sidebar, BarBreadcrumbs },

  head() {
    const title =
      (this.activeGroup.name ?? this.activeGroup.fallback_name) +
      ' - Air Conditioning Parts - AP Air Ltd.';
    const description = '';

    let modifiedLinks = CommonUtils.getDynamicHreflang(
      this,
      this.$nuxtI18nHead({ addSeoAttributes: true }).link,
      this.activeGroup
    );

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ],
      link: modifiedLinks
    }
  },
  async asyncData({ app, params, store }) {
    try {
      const groups = store.getters.getProductGroups
      let activeGroup = {}
      for (let i = 0; i < groups.length; i++) {
        if(groups[i].name === undefined){
          groups[i].name = groups[i].fallback_name
        }
        if (
          app.$slugify(groups[i].name) === params.group ||
          app.$slugify(groups[i].fallback_name) === params.group
        ) {
          activeGroup = groups[i]
          i = groups.length
        }
      }

      const [brands] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}?lang=${store.state.language}`
        )
      ])
      const newBrands = []
      for (let i = 0; i < brands.data[0].brands.length; i++) {
        const brand = brands.data[0].brands[i]
        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              brand.fallback_name =
                brands.data[0].brands[i].translations[j].name
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              brand.name = brands.data[0].brands[i].translations[j].name
            }
          }
          newBrands.push(brand)
        }
      }

      const breadcrumbs = [
        {
          name: app.i18n.t('menu.home'),
          url: 'index'
        },
        {
          name: app.i18n.t('menu.products'),
          url: 'products'
        },
        {
          name:
            activeGroup.name !== ''
              ? activeGroup.name
              : activeGroup.fallback_name,
          url: {
            name: 'products-group',
            params: {
              category: app.$slugify(activeGroup.name)
            }
          }
        }
      ]

      return {
        breadcrumbs,
        activeGroup,
        searchPart: '',
        brands: newBrands,
        originalBrands: newBrands
      }
    } catch {
      app.context.redirect('/')
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Products',
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    filterParts() {
      const newList = []
      for (let i = 0; i < this.originalBrands.length; i++) {
        if (
          this.originalBrands[i].name
            .toLowerCase()
            .includes(this.searchPart.toLowerCase())
        ) {
          newList.push(this.originalBrands[i])
        }
      }
      this.brands = newList
    }
  }
}
</script>

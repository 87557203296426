<template>
  <main id="bauma2022">
    <bar-breadcrumbs :data="breadcrumbs" />
    <header-slider v-if="page.acf.header_slider" :slides="page.acf.header_slider" />
    <section :class="{'py-8': page.acf.header_slider && page.acf.header_slider.length > 0, 'py-16': !page.acf.header_slider || page.acf.header_slider.length == 0}">
      <div class="container">
        <div class="flex flex-wrap justify-center items-center">
          <div
            class="
              w-full px-8 tablet:px-4
            "
          >
            <header-h2
              v-if="false"
              :content="page.title"
              align="center"
              casing="uppercase"
            />
            <div
              v-html="page.content"
              ref="htmlContent"
              class="wp-blocks-content" />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../components/bars/Breadcrumbs'
import wordpress from '~/service/wordpress'
import HeaderSlider from '../components/headers/Slider'

export default {
  name: 'Poweredge',
  components: {
    BarBreadcrumbs,
    HeaderSlider
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.poweredge'),
          url: 'poweredge'
        }
      ]
    }
  },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Air Conditioning Parts - AP Air Ltd.'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        },

        {
          property: 'og:image',
          content: this.page.media.medium
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.page.media.medium
        }
      ]
    }
  },
  async asyncData({ app, store }) {
    const [page] = await Promise.all([
      wordpress.get(`page/poweredge`)
    ])

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
      let questions = this.$refs.htmlContent.getElementsByClassName('question');
      if(questions.length > 0){
        for(var i=0;i<questions.length;i++){
          questions[i].addEventListener('click', function (e) {
            e.preventDefault();
            e.target.closest('.single-question').classList.toggle('active')
          })
        }
      }
    }
  }
}
</script>

<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar />
          </div>
          <div class="w-full tablet:w-3/4">
            <nuxt-link :to="localePath('products')">
              <div class="bg-red text-white p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_1'" />
              </div>
            </nuxt-link>
            <bar-categories :inline="true" />

            <p v-html="$t('components.products.please-note')" class="px-4"></p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs'
import BarCategories from '../../components/bars/Categories'
import Sidebar from '../../components/bars/Sidebar'
export default {
  name: 'Products',
  components: { Sidebar, BarCategories, BarBreadcrumbs },

  head() {
    const title = this.$i18n.t('menu.products') + ' - Air Conditioning Parts - AP Air Ltd.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ]
    }
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('menu.products'),
          url: 'products'
        }
      ]
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Products',
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

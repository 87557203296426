<template>
  <main id="account">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/2 tablet:mx-auto px-4">
            <form @submit.prevent="submitForm">
              <header-h4
                :content="$t('components.account.password_forgotten')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div
                v-if="login_message"
                class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4"
              >
                <div
                  v-html="$t('components.account.login_message')"
                  class="block bg-red px-4 py-2 text-white"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <form-label
                  :label="`${$t('components.account.form.email')} *`"
                  for-id="form_field_login_email"
                  extra-class="block mb-2"
                />
                <input
                  id="form_field_login_email"
                  v-model="login_email"
                  :placeholder="`${$t('components.account.form.email')} *`"
                  type="email"
                  required
                  class="form_input"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <button-primary
                  :text="$t('form.send')"
                  type="submit-form"
                  extra-class="block"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <modal height="auto" name="forgotpassword">
      <div class="h-full p-12 flex flex-wrap">
        <div class="m-auto text-center">
          An email will be sent to you with a link to reset your password. If
          you do not receive it shortly please check your junk folder.
        </div>
        <a class="mx-auto mt-8" href="/">
          <button-primary
            :text="$t('form.returntohomepage')"
            type="submit-form"
            extra-class="block"
          />
        </a>
      </div>
    </modal>
  </main>
</template>

<script>
import BarBreadcrumbs from '../../components/bars/Breadcrumbs'
import portal from '../../service/portal'

export default {
  name: 'PasswordForgotten',
  components: { BarBreadcrumbs },
  head() {
    const title =
      this.$i18n.t('components.account.password_forgotten') + ' - Air Conditioning Parts - AP Air Ltd.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ]
    }
  },
  data() {
    return {
      login_email: '',
      login_message: false,
      breadcrumbs: [
        {
          name: this.$t('menu.home'),
          url: 'index'
        },
        {
          name: this.$t('components.account.password_forgotten'),
          url: 'password-forgotten'
        }
      ]
    }
  },
  methods: {
    submitForm() {
      if (this.login_email !== '') {
        const params = new URLSearchParams()
        params.append('email', this.login_email)
        params.append('lang', this.$i18n.locale)
        portal.post('forgot-password', params)
        this.$modal.show('forgotpassword')
        // window.location.href = '/'
      }
    }
  }
}
</script>

<template>
  <main id="account">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/2 tablet:mx-auto px-4">
            <form @submit.prevent="submitForm">
              <header-h4
                :content="$t('components.account.password_forgotten')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div
                v-if="login_message"
                class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4"
              >
                <div
                  v-html="$t('components.account.password_message')"
                  class="block bg-red px-4 py-2 text-white"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <form-label
                  :label="`${$t('components.account.form.email')} *`"
                  for-id="form_field_login_email"
                  extra-class="block mb-2"
                />
                <input
                  id="form_field_login_email"
                  v-model="login_email"
                  :placeholder="`${$t('components.account.form.email')} *`"
                  type="email"
                  required
                  readonly
                  class="form_input"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <form-label
                  :label="`${$t('components.account.form.password')} *`"
                  for-id="form_field_password"
                  extra-class="block mb-2"
                />
                <form-input
                  v-model="password"
                  :required="true"
                  :placeholder="`${$t('components.account.form.password')} *`"
                  type="password"
                  name="password"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <form-label
                  :label="
                    `${$t('components.account.form.password_confirmation')} *`
                  "
                  for-id="form_field_password_confirmation"
                  extra-class="block mb-2"
                />
                <form-input
                  v-model="password_confirmation"
                  :required="true"
                  :placeholder="
                    `${$t('components.account.form.password_confirmation')} *`
                  "
                  type="password"
                  name="password_confirmation"
                />
              </div>
              <div class="form-group w-full tablet:w-4/5 tablet:mx-auto mb-4">
                <button-primary
                  :text="$t('form.send')"
                  type="submit-form"
                  extra-class="block"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import BarBreadcrumbs from '../../../components/bars/Breadcrumbs'
import portal from '../../../service/portal'

export default {
  name: 'PasswordForgotten',
  components: { BarBreadcrumbs },
  head() {
    const title =
      this.$i18n.t('components.account.password_forgotten') + ' - Air Conditioning Parts - AP Air Ltd.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ]
    }
  },
  async asyncData({ params, redirect, app }) {
    if (!params.token) {
      redirect(app.localePath({ name: 'password-forgotten' }))
    }

    const tokenParams = new URLSearchParams()
    tokenParams.append('token', params.token)
    const [reset] = await Promise.all([
      portal.post(`token-validation`, tokenParams)
    ])
    if (reset.data === 'token_empty' || reset.data === 'token_expired') {
      redirect(app.localePath({ name: 'password-forgotten' }))
    }

    return {
      reset: reset.data,
      login_email: reset.data,
      password: '',
      password_confirmation: '',
      token: params.token,
      login_message: false,
      breadcrumbs: [
        {
          name: app.i18n.t('menu.home'),
          url: 'index'
        },
        {
          name: app.i18n.t('components.account.password_forgotten'),
          url: 'password-forgotten'
        }
      ]
    }
  },
  methods: {
    submitForm() {
      if (
        this.login_email !== '' &&
        this.password !== '' &&
        this.password_confirmation !== ''
      ) {
        if (this.password === this.password_confirmation) {
          const params = new URLSearchParams()
          params.append('email', this.login_email)
          params.append('password', this.password)
          params.append('token', this.token)
          portal.post('reset-password', params)
          window.location.href = this.localePath('account')
        } else {
          this.login_message = true
        }
      }
    }
  }
}
</script>

<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar
              :data="[
                { activeGroup, brands: allBrands },
                { activeBrand, types: allTypes },
                { activeType, models }
              ]"
            />
          </div>
          <div class="w-full tablet:w-3/4">
            <nuxt-link :to="localePath('products')">
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_1'" />
                <span
                  v-html="
                    activeGroup.name
                      ? activeGroup.name
                      : activeGroup.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_2'" />
                <span
                  v-html="
                    activeBrand.name
                      ? activeBrand.name
                      : activeBrand.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group-brand',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    ),
                    brand: $slugify(
                      activeBrand.name
                        ? activeBrand.name
                        : activeBrand.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_3'" />
                <span
                  v-html="
                    activeType.name ? activeType.name : activeType.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group-brand-type',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    ),
                    brand: $slugify(
                      activeBrand.name
                        ? activeBrand.name
                        : activeBrand.fallback_name
                    ),
                    type: $slugify(
                      activeType.name
                        ? activeType.name
                        : activeType.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-red text-white p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_4'" />
              </div>
            </nuxt-link>
            <section class="pb-8">
              <div class="container">
                <div class="flex flex-wrap">
                  <div class="w-full px-4">
                    <header-h5
                      :content="$t('components.search_box.filter')"
                      align="center"
                      casing="uppercase"
                      mt="0"
                      mb="2"
                    />
                    <div class="form_item">
                      <input
                        v-model="searchPart"
                        :placeholder="$t('components.search_box.search_part')"
                        @keyup="filterParts"
                        type="text"
                        class="form_input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="flex flex-wrap">
                <div v-for="model in models" class="w-full tablet:w-1/3 px-4">
                  <div class="py-2">
                    <nuxt-link
                      :to="
                        localePath({
                          name: 'products-group-brand-type-model',
                          params: {
                            group: $slugify(
                              activeGroup.name
                                ? activeGroup.name
                                : activeGroup.fallback_name
                            ),
                            brand: $slugify(
                              activeBrand.name
                                ? activeBrand.name
                                : activeBrand.fallback_name
                            ),
                            type: $slugify(
                              activeType.name
                                ? activeType.name
                                : activeType.fallback_name
                            ),
                            model: $slugify(
                              model.name ? model.name : model.fallback_name
                            )
                          }
                        })
                      "
                      class="text-lg">
                    <font-awesome-icon :icon="['fas', 'arrow-right']" class="text-red"/> {{ model.name ? model.name : model.fallback_name }}
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </section>

            <p v-html="$t('components.products.please-note')" class="px-4"></p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '../../../../../components/bars/Breadcrumbs';
import portal from '../../../../../service/portal';
import Sidebar from '../../../../../components/bars/Sidebar';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Products',
  components: { Sidebar, BarBreadcrumbs },

  head() {
    const title =
      this.activeBrand.name +
      ' - ' +
      this.activeType.name +
      ' - ' +
      (this.activeGroup.name ?? this.activeGroup.fallback_name) +
      ' - Air Conditioning Parts - AP Air Ltd.';
    const description = '';

    let modifiedLinks = CommonUtils.getDynamicHreflang(
      this,
      this.$nuxtI18nHead({ addSeoAttributes: true }).link,
      this.activeGroup,
      this.activeBrand,
      this.activeType
    );

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ],
      link: modifiedLinks
    }
  },
  async asyncData({ app, params, store }) {
    try {
      const groups = store.getters.getProductGroups
      let activeGroup = {}
      let activeBrand = {}
      let activeType = {}
      for (let i = 0; i < groups.length; i++) {
        if (
          app.$slugify(groups[i].name) === params.group ||
          app.$slugify(groups[i].fallback_name) === params.group
        ) {
          activeGroup = groups[i]
          i = groups.length
        }
      }

      const allBrands = []
      const [brands] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}?lang=${store.state.language}`
        )
      ])
      for (let i = 0; i < brands.data[0].brands.length; i++) {
        const brand = brands.data[0].brands[i]

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              brand.fallback_name =
                brands.data[0].brands[i].translations[j].name
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              brand.name = brands.data[0].brands[i].translations[j].name
            }
          }
          allBrands.push(brand)
        }

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].fallback_name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
          }
        }
      }

      const allTypes = []
      const [vehicleTypes] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}/brands/${activeBrand.id}?lang=${store.state.language}`
        )
      ])

      for (let i = 0; i < vehicleTypes.data[0].vehicle_types.length; i++) {
        const vehicleType = vehicleTypes.data[0].vehicle_types[i]
        if (vehicleTypes.data[0].vehicle_types[i] !== null) {
          for (
            let j = 0;
            j < vehicleTypes.data[0].vehicle_types[i].translations.length;
            j++
          ) {
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === 'en'
            ) {
              vehicleType.fallback_name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === store.state.language
            ) {
              vehicleType.name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
          }
          allTypes.push(vehicleType)
        }

        if (vehicleTypes.data[0].vehicle_types[i] !== null) {
          for (
            let j = 0;
            j < vehicleTypes.data[0].vehicle_types[i].translations.length;
            j++
          ) {
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === 'en'
            ) {
              if (
                app.$slugify(
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                ) === params.type
              ) {
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].fallback_name =
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                activeType = vehicleTypes.data[0].vehicle_types[i]
              }
            }
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === store.state.language
            ) {
              if (
                app.$slugify(
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                ) === params.type
              ) {
                vehicleTypes.data[0].vehicle_types[i].name =
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                activeType = vehicleTypes.data[0].vehicle_types[i]
              }
            }
          }
        }
      }

      const [models] = await Promise.all([
        portal.get(
          `vehicle-types/${activeType.id}?lang=${store.state.language}`
        )
      ])

      const newModels = []
      for (let i = 0; i < models.data[0].vehicles.length; i++) {
        const model = models.data[0].vehicles[i]

        for (
          let j = 0;
          j < models.data[0].vehicles[i].translations.length;
          j++
        ) {
          if (
            models.data[0].vehicles[i].translations[j].language
              .language_code === 'en'
          ) {
            model.fallback_name =
              models.data[0].vehicles[i].translations[j].name
          }
          if (
            models.data[0].vehicles[i].translations[j].language
              .language_code === store.state.language
          ) {
            model.name = models.data[0].vehicles[i].translations[j].name
          }
        }
        newModels.push(model)
      }

      const breadcrumbs = [
        {
          name: app.i18n.t('menu.home'),
          url: 'index'
        },
        {
          name: app.i18n.t('menu.products'),
          url: 'products'
        },
        {
          name:
            activeGroup.name !== ''
              ? activeGroup.name
              : activeGroup.fallback_name,
          url: {
            name: 'products-group',
            params: {
              category: app.$slugify(activeGroup.name)
            }
          }
        },
        {
          name: activeBrand.name,
          url: {
            name: 'products-group-brand',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name)
            }
          }
        },
        {
          name: activeType.name,
          url: {
            name: 'products-group-brand-type',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name),
              type: app.$slugify(activeType.name)
            }
          }
        }
      ]

      return {
        breadcrumbs,
        activeGroup,
        activeBrand,
        activeType,
        allBrands,
        allTypes,
        searchPart: '',
        models: newModels.sort((a, b) =>
          a.fallback_name < b.fallback_name
            ? -1
            : a.fallback_name > b.fallback_name
            ? 1
            : 0
        ),
        originalModels: newModels
      }
    } catch {
      app.context.redirect('/')
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Products',
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    filterParts() {
      const newList = []
      for (let i = 0; i < this.originalModels.length; i++) {
        if (
          this.originalModels[i].name &&
          this.originalModels[i].name
            .toLowerCase()
            .includes(this.searchPart.toLowerCase())
        ) {
          newList.push(this.originalModels[i])
        } else if (
          this.originalModels[i].fallback_name &&
          this.originalModels[i].fallback_name
            .toLowerCase()
            .includes(this.searchPart.toLowerCase())
        ) {
        }
      }
      this.models = newList.sort((a, b) =>
        a.fallback_name < b.fallback_name
          ? -1
          : a.fallback_name > b.fallback_name
          ? 1
          : 0
      )
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"products"}},[_c('bar-breadcrumbs',{attrs:{"data":_vm.breadcrumbs}}),_vm._v(" "),_c('section',{staticClass:"bar__categories"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap flex-col-reverse tablet:flex-row"},[_c('div',{staticClass:"w-full tablet:w-1/4 px-4"},[_c('sidebar',{attrs:{"data":[
              { activeGroup: _vm.activeGroup, brands: _vm.allBrands },
              { activeBrand: _vm.activeBrand, types: _vm.allTypes },
              { activeType: _vm.activeType, models: _vm.allModels },
              { activeModel: _vm.activeModel }
            ]}})],1),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4"},[_c('product-detail',{attrs:{"data":_vm.activePart}}),_vm._v(" "),_c('p',{staticClass:"px-4",domProps:{"innerHTML":_vm._s(_vm.$t('components.products.please-note'))}}),_vm._v(" "),(_vm.alternativeProducts.length > 0)?_c('div',{staticClass:"alternative_products mt-20"},[_c('header-h5',{attrs:{"content":_vm.$t('components.products.alternative'),"align":"center","casing":"uppercase","mt":"0","mb":"4"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.alternativeProducts),function(product){return _c('div',{staticClass:"w-full tablet:w-1/3 px-4"},[_c('product-quick',{attrs:{"data":product,"link":{
                    name: 'product-product',
                    params: {
                      product: _vm.$slugify(product.link)
                    }
                  }}})],1)}),0)],1):_vm._e(),_vm._v(" "),(_vm.spareProducts.length > 0)?_c('div',{staticClass:"alternative_products mt-20"},[_c('header-h5',{attrs:{"content":_vm.$t('components.products.spare_parts'),"align":"center","casing":"uppercase","mt":"0","mb":"4"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.spareProducts),function(product){return _c('div',{staticClass:"w-full tablet:w-1/3 px-4"},[_c('product-quick',{attrs:{"data":product,"link":{
                    name: 'product-product',
                    params: {
                      product: _vm.$slugify(product.link)
                    }
                  }}})],1)}),0)],1):_vm._e()],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
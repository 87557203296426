<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar
              :data="[
                { activeGroup, brands: allBrands },
                { activeBrand, types: allTypes },
                { activeType, models: allModels },
                { activeModel }
              ]"
            />
          </div>
          <div class="w-full tablet:w-3/4">
            <nuxt-link :to="localePath('products')">
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_1'" />
                <span
                  v-html="
                    activeGroup.name
                      ? activeGroup.name
                      : activeGroup.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_2'" />
                <span
                  v-html="
                    activeBrand.name
                      ? activeBrand.name
                      : activeBrand.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group-brand',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    ),
                    brand: $slugify(
                      activeBrand.name
                        ? activeBrand.name
                        : activeBrand.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_3'" />
                <span
                  v-html="
                    activeType.name ? activeType.name : activeType.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group-brand-type',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    ),
                    brand: $slugify(
                      activeBrand.name
                        ? activeBrand.name
                        : activeBrand.fallback_name
                    ),
                    type: $slugify(
                      activeType.name
                        ? activeType.name
                        : activeType.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_4'" />
                <span
                  v-html="
                    activeModel.name
                      ? activeModel.name
                      : activeModel.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <section v-if="products.length === 0">
              <div class="flex flex-wrap">
                <div class="w-full px-4">
                  <div
                    v-t="'components.products.no_products_found'"
                    class="mb-4 mt-6 bg-orange text-white font-medium uppercase text-lg px-4 py-2"
                  />
                </div>
              </div>
            </section>
            <section v-else>
              <div class="groups-tabs w-full px-4" id="top-groups">
                <ul>
                  <li v-for="productGroup in products">
                    <a v-scroll-to="{ el: `#group-${convertToSlug(productGroup.name)}`, offset: -180}"
                      v-html="
                          productGroup.name !== ''
                            ? productGroup.name
                            : $t('components.products.other')
                        "
                    />
                  </li>
                </ul>
              </div>

              <div
                v-if="refrigerant"
                class="w-full pb-5 px-4">
                <div class="bg-red text-white font-medium p-2">
                  {{ refrigerantTranslationObj.name }}
                </div>
                <div class="product__item flex flex-wrap py-4 my-4">
                  <div class="w-full tablet:w-1/5 px-4 flex items-center justify-center">
                    <div class="product__image">
                      <template v-if="refrigerant.images && refrigerant.images.length > 0">
                        <img
                          :src="`${refrigerant.images[0]}`"
                          :alt="`${refrigerant.sku} - ${refrigerant.name}`" />
                      </template>
                      <template v-else>
                        <img
                          :alt="`${refrigerant.sku} - ${refrigerant.name}`"
                          src="/images/placeholder.jpg" />
                      </template>
                    </div>
                  </div>
                  <div class="w-full tablet:w-4/5 px-4">
                    <template v-if="isLoggedIn">
                      <p
                        v-html="refrigerantTranslationObj.description.replace(/(?:\r\n|\r|\n)/g, '<br>')"
                        class="mt-0">
                      </p>
                      <p
                        v-if="refrigerantTranslationObj.part_specification_1"
                        v-html="refrigerantTranslationObj.part_specification_1.replace(/(?:\r\n|\r|\n)/g, '<br>')">
                      </p>
                      <p
                        v-if="refrigerantTranslationObj.part_specification_2"
                        v-html="refrigerantTranslationObj.part_specification_2.replace(/(?:\r\n|\r|\n)/g, '<br>')">
                      </p>
                      <p
                        v-if="refrigerantTranslationObj.part_specification_3"
                        v-html="refrigerantTranslationObj.part_specification_3.replace(/(?:\r\n|\r|\n)/g, '<br>')">
                      </p>
                    </template>
                    <template v-else>
                      <div class="text-right flex h-full">
                        <nuxt-link
                          :to="localePath('account')"
                          class="ml-auto my-auto" >
                          {{ $t('components.account.loginOrSubscribeHere') }}
                        </nuxt-link>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full px-4">
                  <div v-for="productGroup in products">
                    <div :id="`group-${convertToSlug(productGroup.name)}`"
                      class="bg-red text-white font-medium uppercase p-2 mb-4"
                    >
                      <span
                        v-html="
                          productGroup.name !== ''
                            ? productGroup.name
                            : $t('components.products.other')
                        "
                      />
                    </div>
                    <div v-for="product in productGroup.products">
                      <product-item
                        :data="product"
                        :link="{
                          name: 'products-group-brand-type-model-product',
                          params: {
                            group: $slugify(
                              activeGroup.name
                                ? activeGroup.name
                                : activeGroup.fallback_name
                            ),
                            brand: $slugify(
                              activeBrand.name
                                ? activeBrand.name
                                : activeBrand.fallback_name
                            ),
                            type: $slugify(
                              activeType.name
                                ? activeType.name
                                : activeType.fallback_name
                            ),
                            model: $slugify(
                              activeModel.name
                                ? activeModel.name
                                : activeModel.fallback_name
                            ),
                            product: $slugify(product.link)
                          }
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <p v-html="$t('components.products.please-note')" class="px-4"></p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '../../../../../../components/bars/Breadcrumbs';
import portal from '../../../../../../service/portal';
import ProductItem from '../../../../../../components/products/Item';
import Sidebar from '../../../../../../components/bars/Sidebar';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Products',
  components: { Sidebar, ProductItem, BarBreadcrumbs },

  head() {
    const title =
      this.activeBrand.name +
      ' ' +
      (this.activeModel.name ?? this.activeModel.fallback_name) +
      ' - ' +
      this.activeType.name +
      ' - ' +
      (this.activeGroup.name ?? this.activeGroup.fallback_name) +
      ' - Air Conditioning Parts - AP Air Ltd.';
    const description = '';

    let modifiedLinks = CommonUtils.getDynamicHreflang(
      this,
      this.$nuxtI18nHead({ addSeoAttributes: true }).link,
      this.activeGroup,
      this.activeBrand,
      this.activeType,
      this.activeModel
    );

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ],
      link: modifiedLinks
    }
  },
  async asyncData({ app, params, store }) {
    try {
      const groups = store.getters.getProductGroups
      let activeGroup = {}
      let activeBrand = {}
      let activeType = {}
      let activeModel = {}
      for (let i = 0; i < groups.length; i++) {
        if (
          app.$slugify(groups[i].name) === params.group ||
          app.$slugify(groups[i].fallback_name) === params.group
        ) {
          activeGroup = groups[i]
          i = groups.length
        }
      }

      const allBrands = []
      const [brands] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}?lang=${store.state.language}`
        )
      ])
      for (let i = 0; i < brands.data[0].brands.length; i++) {
        const brand = brands.data[0].brands[i]

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              brand.fallback_name =
                brands.data[0].brands[i].translations[j].name
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              brand.name = brands.data[0].brands[i].translations[j].name
            }
          }
          allBrands.push(brand)
        }

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].fallback_name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
          }
        }
      }

      const allTypes = []
      const [vehicleTypes] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}/brands/${activeBrand.id}?lang=${store.state.language}`
        )
      ])
      for (let i = 0; i < vehicleTypes.data[0].vehicle_types.length; i++) {
        const vehicleType = vehicleTypes.data[0].vehicle_types[i]
        if (vehicleTypes.data[0].vehicle_types[i] !== null) {
          for (
            let j = 0;
            j < vehicleTypes.data[0].vehicle_types[i].translations.length;
            j++
          ) {
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === 'en'
            ) {
              vehicleType.fallback_name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === store.state.language
            ) {
              vehicleType.name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
          }
          allTypes.push(vehicleType)
        }

        if (vehicleTypes.data[0].vehicle_types[i] !== null) {
          for (
            let j = 0;
            j < vehicleTypes.data[0].vehicle_types[i].translations.length;
            j++
          ) {
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === 'en'
            ) {
              if (
                app.$slugify(
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                ) === params.type
              ) {
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].fallback_name =
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                activeType = vehicleTypes.data[0].vehicle_types[i]
              }
            }
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === store.state.language
            ) {
              if (
                app.$slugify(
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                ) === params.type
              ) {
                vehicleTypes.data[0].vehicle_types[i].name =
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                activeType = vehicleTypes.data[0].vehicle_types[i]
              }
            }
          }
        }
      }

      const allModels = []
      const [models] = await Promise.all([
        portal.get(
          `vehicle-types/${activeType.id}?lang=${store.state.language}`
        )
      ])
      for (let i = 0; i < models.data[0].vehicles.length; i++) {
        const model = models.data[0].vehicles[i]
        for (
          let j = 0;
          j < models.data[0].vehicles[i].translations.length;
          j++
        ) {
          for (
            let j = 0;
            j < models.data[0].vehicles[i].translations.length;
            j++
          ) {
            if (
              models.data[0].vehicles[i].translations[j].language
                .language_code === 'en'
            ) {
              model.fallback_name =
                models.data[0].vehicles[i].translations[j].name
            }
            if (
              models.data[0].vehicles[i].translations[j].language
                .language_code === store.state.language
            ) {
              model.name = models.data[0].vehicles[i].translations[j].name
            }
          }
          if (
            models.data[0].vehicles[i].translations[j].language
              .language_code === 'en'
          ) {
            if (
              app.$slugify(models.data[0].vehicles[i].translations[j].name) ===
              params.model
            ) {
              // eslint-disable-next-line standard/computed-property-even-spacing
              models.data[0].vehicles[i].fallback_name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                models.data[0].vehicles[i].translations[j].name
              activeModel = models.data[0].vehicles[i]
            }
          }
          if (
            models.data[0].vehicles[i].translations[j].language
              .language_code === store.state.language
          ) {
            if (
              app.$slugify(models.data[0].vehicles[i].translations[j].name) ===
              params.model
            ) {
              // eslint-disable-next-line standard/computed-property-even-spacing
              models.data[0].vehicles[i].name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                models.data[0].vehicles[i].translations[j].name
              activeModel = models.data[0].vehicles[i]
            }
          }
        }
        allModels.push(model)
      }

      const [products] = await Promise.all([
        portal.get(`vehicles/${activeModel.id}?lang=${store.state.language}`)
      ])

      for (let i = 0; i < products.data.products.length; i++) {
        for (let j = 0; j < products.data.products[i].products.length; j++) {
          products.data.products[i].products[j].link =
            products.data.products[i].products[j].id +
            ' ' +
            products.data.products[i].products[j].sku +
            ' ' +
            products.data.products[i].products[j].name
        }
      }

      const breadcrumbs = [
        {
          name: app.i18n.t('menu.home'),
          url: 'index'
        },
        {
          name: app.i18n.t('menu.products'),
          url: 'products'
        },
        {
          name:
            activeGroup.name !== ''
              ? activeGroup.name
              : activeGroup.fallback_name,
          url: {
            name: 'products-group',
            params: {
              category: app.$slugify(activeGroup.name)
            }
          }
        },
        {
          name: activeBrand.name,
          url: {
            name: 'products-group-brand',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name)
            }
          }
        },
        {
          name: activeType.name,
          url: {
            name: 'products-group-brand-type',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name),
              type: app.$slugify(activeType.name)
            }
          }
        },
        {
          name: activeModel.name ?? activeModel.fallback_name,
          url: {
            name: 'products-group-brand-type-model',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name),
              type: app.$slugify(activeType.name),
              model: app.$slugify(activeModel.name ?? activeModel.fallback_name)
            }
          }
        }
      ]

      return {
        breadcrumbs,
        activeGroup,
        activeBrand,
        activeType,
        activeModel,
        allBrands,
        allTypes,
        allModels,
        products: products.data.products,
        rawProducts: products.data,
        refrigerant: products.data.refrigerant
      }
    } catch {
      app.context.redirect('/')
    }
  },
  computed: {
    isLoggedIn () {
      if (!this.$auth) {
        console.log('nnie ma');
        return false;
      }
      return this.$auth.loggedIn;
    },
    refrigerantTranslationObj () {
      if (!this.refrigerant || !this.refrigerant.translations) {
        return null;
      }

      let translationObj = this.refrigerant.translations.find(item => item.language && item.language.language_code === this.$store.state.language);

      if (translationObj) {
        return translationObj
      }

      return this.refrigerant.translations.find(item => item.language && item.language.language_code === 'en');
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Products',
        page_path: this.$route.fullPath
      })
    }

    if (process.client && this.$auth.loggedIn && !this.$store.getters.getIsFavoritesSet) {
      this.loadFavorites();
    }
  },
  methods: {
    convertToSlug(stringData) {
      stringData = stringData
      .toLowerCase() // LowerCase
      .replace(/\s+/g, "-") // space to -
      .replace(/&/g, `-and-`) // & to and
      .replace(/--/g, `-`) // -- to -
      .replace(/'/g, `-`) // ' to -
      .replace(/\//g, `-`); // / to -
      return stringData;
    },
    loadFavorites () {
      portal.get(`favourites/get-all/${this.$auth.user.user_id}`)
      .then((response) => {
        this.$store.commit('SET_FAVORITE_ITEMS', response.data.products.map(item => item.product_id));
        this.$store.commit('SET_FAVORITE_ITEMS_FETCHED', true);
      });
    }
  }
}
</script>

<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar
              :data="[
                { activeGroup, brands: allBrands },
                { activeBrand, types: vehicleTypes }
              ]"
            />
          </div>
          <div class="w-full tablet:w-3/4">
            <nuxt-link :to="localePath('products')">
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_1'" />
                <span
                  v-html="
                    activeGroup.name
                      ? activeGroup.name
                      : activeGroup.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-gray-medium text-black p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_2'" />
                <span
                  v-html="
                    activeBrand.name
                      ? activeBrand.name
                      : activeBrand.fallback_name
                  "
                  class="tablet:ml-2"
                />
              </div>
            </nuxt-link>
            <nuxt-link
              :to="
                localePath({
                  name: 'products-group-brand',
                  params: {
                    group: $slugify(
                      activeGroup.name
                        ? activeGroup.name
                        : activeGroup.fallback_name
                    ),
                    brand: $slugify(
                      activeBrand.name
                        ? activeBrand.name
                        : activeBrand.fallback_name
                    )
                  }
                })
              "
            >
              <div class="bg-red text-white p-2 mb-4 mx-4">
                <strong v-t="'components.products.selector.step_3'" />
              </div>
            </nuxt-link>
            <section>
              <div class="grid grid-cols-1 tablet:grid-cols-3 gap-5 laptop:gap-8 px-4 pb-8">
                <nuxt-link
                  v-for="(vehicleType, vehicleTypeIndex) in vehicleTypes"
                  :key="'products-group-brand-type' + vehicleTypeIndex"
                  class="category"
                  :to="
                    localePath({
                      name: 'products-group-brand-type',
                      params: {
                        group: $slugify(
                          activeGroup.name
                            ? activeGroup.name
                            : activeGroup.fallback_name
                        ),
                        brand: $slugify(
                          activeBrand.name
                            ? activeBrand.name
                            : activeBrand.fallback_name
                        ),
                        type: $slugify(
                          vehicleType.name
                            ? vehicleType.name
                            : vehicleType.fallback_name
                        )
                      }
                    })
                  "
                >
                  <img
                    :alt="
                      vehicleType.name
                        ? vehicleType.name
                        : vehicleType.fallback_name
                    "
                    :src="
                      vehicleType.images.length > 0
                        ? vehicleType.images[0]
                        : '/images/placeholder-selector.jpg'
                    "
                    class="mx-auto"/>
                  <p class="text-center uppercase mt-2 mb-0">
                    <strong>
                      {{ vehicleType.name ? vehicleType.name : vehicleType.fallback_name }}
                    </strong>
                  </p>
                </nuxt-link>
              </div>
            </section>
            <div class="bg-gray-dark text-white p-2 mb-4 mx-4">
              <strong v-t="'components.products.selector.step_4'" />
            </div>

            <p v-html="$t('components.products.please-note')" class="px-4"></p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '../../../../components/bars/Breadcrumbs';
import portal from '../../../../service/portal';
import Sidebar from '../../../../components/bars/Sidebar';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Products',
  components: { Sidebar, BarBreadcrumbs },

  head() {
    const title =
      this.activeBrand.name +
      ' - ' +
      (this.activeGroup.name ?? this.activeGroup.fallback_name) +
      ' - Air Conditioning Parts - AP Air Ltd.';
    const description = '';

    let modifiedLinks = CommonUtils.getDynamicHreflang(
      this,
      this.$nuxtI18nHead({ addSeoAttributes: true }).link,
      this.activeGroup,
      this.activeBrand
    );

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ],
      link: modifiedLinks
    }
  },
  async asyncData({ app, params, store }) {
    try {
      const groups = store.getters.getProductGroups
      let activeGroup = {}
      let activeBrand = {}
      for (let i = 0; i < groups.length; i++) {
        if (
          app.$slugify(groups[i].name) === params.group ||
          app.$slugify(groups[i].fallback_name) === params.group
        ) {
          activeGroup = groups[i]
          i = groups.length
        }
      }

      const allBrands = []
      const [brands] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}?lang=${store.state.language}`
        )
      ])

      for (let i = 0; i < brands.data[0].brands.length; i++) {
        const brand = brands.data[0].brands[i]

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              brand.fallback_name =
                brands.data[0].brands[i].translations[j].name
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              brand.name = brands.data[0].brands[i].translations[j].name
            }
          }
          allBrands.push(brand)
        }

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].fallback_name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
          }
        }
      }

      const [vehicleTypes] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}/brands/${activeBrand.id}?lang=${store.state.language}`
        )
      ])
      const newVehicleTypes = []
      for (let i = 0; i < vehicleTypes.data[0].vehicle_types.length; i++) {
        const vehicleType = vehicleTypes.data[0].vehicle_types[i]
        if (vehicleTypes.data[0].vehicle_types[i] !== null) {
          for (
            let j = 0;
            j < vehicleTypes.data[0].vehicle_types[i].translations.length;
            j++
          ) {
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === 'en'
            ) {
              vehicleType.fallback_name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === store.state.language
            ) {
              vehicleType.name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
          }
          newVehicleTypes.push(vehicleType)
        }
      }

      const breadcrumbs = [
        {
          name: app.i18n.t('menu.home'),
          url: 'index'
        },
        {
          name: app.i18n.t('menu.products'),
          url: 'products'
        },
        {
          name:
            activeGroup.name !== ''
              ? activeGroup.name
              : activeGroup.fallback_name,
          url: {
            name: 'products-group',
            params: {
              category: app.$slugify(activeGroup.name)
            }
          }
        },
        {
          name: activeBrand.name,
          url: {
            name: 'products-group-brand',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name)
            }
          }
        }
      ]

      return {
        breadcrumbs,
        activeGroup,
        activeBrand,
        allBrands,
        vehicleTypes: newVehicleTypes
      }
    } catch {
      app.context.redirect('/')
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Products',
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"products"}},[_c('bar-breadcrumbs',{attrs:{"data":_vm.breadcrumbs}}),_vm._v(" "),_c('section',{staticClass:"bar__categories"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap flex-col-reverse tablet:flex-row"},[_c('div',{staticClass:"w-full tablet:w-1/4 px-4"},[_c('sidebar',{attrs:{"data":[{ activeGroup: _vm.activeGroup, brands: _vm.brands }]}})],1),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('products')}},[_c('div',{staticClass:"bg-gray-medium text-black p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_1'),expression:"'components.products.selector.step_1'"}]}),_vm._v(" "),_c('span',{staticClass:"tablet:ml-2",domProps:{"innerHTML":_vm._s(
                  _vm.activeGroup.name
                    ? _vm.activeGroup.name
                    : _vm.activeGroup.fallback_name
                )}})])]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.localePath({
                name: 'products-group',
                params: {
                  group: _vm.$slugify(
                    _vm.activeGroup.name
                      ? _vm.activeGroup.name
                      : _vm.activeGroup.fallback_name
                  )
                }
              })}},[_c('div',{staticClass:"bg-red text-white p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_2'),expression:"'components.products.selector.step_2'"}]})])]),_vm._v(" "),_c('section',{staticClass:"pb-8"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full px-4"},[_c('header-h5',{attrs:{"content":_vm.$t('components.search_box.filter'),"align":"center","casing":"uppercase","mt":"0","mb":"2"}}),_vm._v(" "),_c('div',{staticClass:"form_item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchPart),expression:"searchPart"}],staticClass:"form_input",attrs:{"placeholder":_vm.$t('components.search_box.search_part'),"type":"text"},domProps:{"value":(_vm.searchPart)},on:{"keyup":_vm.filterParts,"input":function($event){if($event.target.composing){ return; }_vm.searchPart=$event.target.value}}})])],1)])])]),_vm._v(" "),_c('section',[_c('div',{staticClass:"grid grid-cols-1 tablet:grid-cols-3 gap-5 laptop:gap-8 px-4 pb-8"},[_vm._l((_vm.brands),function(brand){return [(brand !== null)?_c('nuxt-link',{staticClass:"category",attrs:{"to":_vm.localePath({
                      name: 'products-group-brand',
                      params: {
                        group: _vm.$slugify(
                          _vm.activeGroup.name
                            ? _vm.activeGroup.name
                            : _vm.activeGroup.fallback_name
                        ),
                        brand: _vm.$slugify(
                          brand.name ? brand.name : brand.fallback_name
                        )
                      }
                    })}},[_c('img',{staticClass:"mx-auto",staticStyle:{"max-height":"75px"},attrs:{"alt":brand.name ? brand.name : brand.fallback_name,"src":brand.images.length > 0
                        ? brand.images[0]
                        : '/images/placeholder-selector.jpg'}}),_vm._v(" "),_c('p',{staticClass:"text-center uppercase mt-2 mb-0"},[_c('strong',[_vm._v("\n                      "+_vm._s(brand.name ? brand.name : brand.fallback_name)+"\n                    ")])])]):_vm._e()]})],2)]),_vm._v(" "),_c('div',{staticClass:"bg-gray-dark text-white p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_3'),expression:"'components.products.selector.step_3'"}]})]),_vm._v(" "),_c('div',{staticClass:"bg-gray-dark text-white p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_4'),expression:"'components.products.selector.step_4'"}]})]),_vm._v(" "),_c('p',{staticClass:"px-4",domProps:{"innerHTML":_vm._s(_vm.$t('components.products.please-note'))}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
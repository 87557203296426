var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"products"}},[_c('bar-breadcrumbs',{attrs:{"data":_vm.breadcrumbs}}),_vm._v(" "),_c('section',{staticClass:"bar__categories"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap flex-col-reverse tablet:flex-row"},[_c('div',{staticClass:"w-full tablet:w-1/4 px-4"},[_c('sidebar',{attrs:{"data":[
              { activeGroup: _vm.activeGroup, brands: _vm.allBrands },
              { activeBrand: _vm.activeBrand, types: _vm.vehicleTypes }
            ]}})],1),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-3/4"},[_c('nuxt-link',{attrs:{"to":_vm.localePath('products')}},[_c('div',{staticClass:"bg-gray-medium text-black p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_1'),expression:"'components.products.selector.step_1'"}]}),_vm._v(" "),_c('span',{staticClass:"tablet:ml-2",domProps:{"innerHTML":_vm._s(
                  _vm.activeGroup.name
                    ? _vm.activeGroup.name
                    : _vm.activeGroup.fallback_name
                )}})])]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.localePath({
                name: 'products-group',
                params: {
                  group: _vm.$slugify(
                    _vm.activeGroup.name
                      ? _vm.activeGroup.name
                      : _vm.activeGroup.fallback_name
                  )
                }
              })}},[_c('div',{staticClass:"bg-gray-medium text-black p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_2'),expression:"'components.products.selector.step_2'"}]}),_vm._v(" "),_c('span',{staticClass:"tablet:ml-2",domProps:{"innerHTML":_vm._s(
                  _vm.activeBrand.name
                    ? _vm.activeBrand.name
                    : _vm.activeBrand.fallback_name
                )}})])]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":_vm.localePath({
                name: 'products-group-brand',
                params: {
                  group: _vm.$slugify(
                    _vm.activeGroup.name
                      ? _vm.activeGroup.name
                      : _vm.activeGroup.fallback_name
                  ),
                  brand: _vm.$slugify(
                    _vm.activeBrand.name
                      ? _vm.activeBrand.name
                      : _vm.activeBrand.fallback_name
                  )
                }
              })}},[_c('div',{staticClass:"bg-red text-white p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_3'),expression:"'components.products.selector.step_3'"}]})])]),_vm._v(" "),_c('section',[_c('div',{staticClass:"grid grid-cols-1 tablet:grid-cols-3 gap-5 laptop:gap-8 px-4 pb-8"},_vm._l((_vm.vehicleTypes),function(vehicleType,vehicleTypeIndex){return _c('nuxt-link',{key:'products-group-brand-type' + vehicleTypeIndex,staticClass:"category",attrs:{"to":_vm.localePath({
                    name: 'products-group-brand-type',
                    params: {
                      group: _vm.$slugify(
                        _vm.activeGroup.name
                          ? _vm.activeGroup.name
                          : _vm.activeGroup.fallback_name
                      ),
                      brand: _vm.$slugify(
                        _vm.activeBrand.name
                          ? _vm.activeBrand.name
                          : _vm.activeBrand.fallback_name
                      ),
                      type: _vm.$slugify(
                        vehicleType.name
                          ? vehicleType.name
                          : vehicleType.fallback_name
                      )
                    }
                  })}},[_c('img',{staticClass:"mx-auto",attrs:{"alt":vehicleType.name
                      ? vehicleType.name
                      : vehicleType.fallback_name,"src":vehicleType.images.length > 0
                      ? vehicleType.images[0]
                      : '/images/placeholder-selector.jpg'}}),_vm._v(" "),_c('p',{staticClass:"text-center uppercase mt-2 mb-0"},[_c('strong',[_vm._v("\n                    "+_vm._s(vehicleType.name ? vehicleType.name : vehicleType.fallback_name)+"\n                  ")])])])}),1)]),_vm._v(" "),_c('div',{staticClass:"bg-gray-dark text-white p-2 mb-4 mx-4"},[_c('strong',{directives:[{name:"t",rawName:"v-t",value:('components.products.selector.step_4'),expression:"'components.products.selector.step_4'"}]})]),_vm._v(" "),_c('p',{staticClass:"px-4",domProps:{"innerHTML":_vm._s(_vm.$t('components.products.please-note'))}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <main id="products">
    <bar-breadcrumbs :data="breadcrumbs" />
    <section class="bar__categories">
      <div class="container">
        <div class="flex flex-wrap flex-col-reverse tablet:flex-row">
          <div class="w-full tablet:w-1/4 px-4">
            <sidebar
              :data="[
                { activeGroup, brands: allBrands },
                { activeBrand, types: allTypes },
                { activeType, models: allModels },
                { activeModel }
              ]"
            />
          </div>
          <div class="w-full tablet:w-3/4">
            <product-detail :data="activePart" />
            <p v-html="$t('components.products.please-note')" class="px-4"></p>

            <div
              v-if="alternativeProducts.length > 0"
              class="alternative_products mt-20"
            >
              <header-h5
                :content="$t('components.products.alternative')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div class="flex flex-wrap">
                <div
                  v-for="product in alternativeProducts"
                  class="w-full tablet:w-1/3 px-4"
                >
                  <product-quick
                    :data="product"
                    :link="{
                      name: 'product-product',
                      params: {
                        product: $slugify(product.link)
                      }
                    }"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="spareProducts.length > 0"
              class="alternative_products mt-20"
            >
              <header-h5
                :content="$t('components.products.spare_parts')"
                align="center"
                casing="uppercase"
                mt="0"
                mb="4"
              />
              <div class="flex flex-wrap">
                <div
                  v-for="product in spareProducts"
                  class="w-full tablet:w-1/3 px-4"
                >
                  <product-quick
                    :data="product"
                    :link="{
                      name: 'product-product',
                      params: {
                        product: $slugify(product.link)
                      }
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import BarBreadcrumbs from '/components/bars/Breadcrumbs';
import portal from '/service/portal';
import ProductDetail from '/components/products/Detail';
import Sidebar from '/components/bars/Sidebar';
import ProductQuick from '/components/products/Quick';
import CommonUtils from '/helpers/CommonUtils';

export default {
  name: 'Product',
  components: {
    ProductQuick,
    Sidebar,
    ProductDetail,
    BarBreadcrumbs
  },

  head() {
    const title = this.activePart.product.meta_title ??
      this.activePart.product.name +
      (!this.activePart.to_be_anounced ? ' - ' + this.activePart.sku : '') +
      ' - Air Conditioning Parts - AP Air Ltd.';
    const description = !this.activePart.product.description ? '' : this.activePart.product.description;
    const googleDescription = this.activePart.product.meta_description ?? description;
    const og_image = this.activePart.images[0] ?? 'https://www.apairltd.com/images/placeholder.jpg';

    let modifiedLinks = CommonUtils.getDynamicHreflang(
      this,
      this.$nuxtI18nHead({ addSeoAttributes: true }).link,
      this.activeGroup,
      this.activeBrand,
      this.activeType,
      this.activeModel,
      this.activePart
    );

    let productPath = (this.localePath({
      name: 'product-product',
      params: {
        product: this.$slugify(this.activePart.link)
      }
    }));
    let productCanonical = 'https://www.apairltd.com' + productPath;
    modifiedLinks.push({
      hid: 'i18n-can',
      rel: 'canonical',
      href: productCanonical
    })

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: googleDescription
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:image', content: og_image },
        { property: 'og:title', content: title },
        { property: 'og:description', content: googleDescription },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {
          innerHTML: JSON.stringify(this.structuredData),
          type: 'application/ld+json'
        }
      ],
      link: modifiedLinks
    }
  },
  async asyncData({ app, params, store }) {
    try {
      const groups = store.getters.getProductGroups
      let activeGroup = {}
      let activeBrand = {}
      let activeType = {}
      let activeModel = {}
      let activePart = {}

      for (let i = 0; i < groups.length; i++) {
        if (
          app.$slugify(groups[i].name) === params.group ||
          app.$slugify(groups[i].fallback_name) === params.group
        ) {
          activeGroup = groups[i]
          i = groups.length
        }
      }
      const allBrands = []
      const [brands] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}?lang=${store.state.language}`
        )
      ])
      for (let i = 0; i < brands.data[0].brands.length; i++) {
        const brand = brands.data[0].brands[i]

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              brand.fallback_name =
                brands.data[0].brands[i].translations[j].name
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              brand.name = brands.data[0].brands[i].translations[j].name
            }
          }
          allBrands.push(brand)
        }

        if (brands.data[0].brands[i] !== null) {
          for (
            let j = 0;
            j < brands.data[0].brands[i].translations.length;
            j++
          ) {
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === 'en'
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].fallback_name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
            if (
              brands.data[0].brands[i].translations[j].language
                .language_code === store.state.language
            ) {
              if (
                app.$slugify(brands.data[0].brands[i].translations[j].name) ===
                params.brand
              ) {
                brands.data[0].brands[i].name =
                  brands.data[0].brands[i].translations[j].name
                activeBrand = brands.data[0].brands[i]
              }
            }
          }
        }
      }

      const allTypes = []
      const [vehicleTypes] = await Promise.all([
        portal.get(
          `productgroups/${activeGroup.id}/brands/${activeBrand.id}?lang=${store.state.language}`
        )
      ])
      for (let i = 0; i < vehicleTypes.data[0].vehicle_types.length; i++) {
        const vehicleType = vehicleTypes.data[0].vehicle_types[i]
        if (vehicleTypes.data[0].vehicle_types[i] !== null) {
          for (
            let j = 0;
            j < vehicleTypes.data[0].vehicle_types[i].translations.length;
            j++
          ) {
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === 'en'
            ) {
              vehicleType.fallback_name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === store.state.language
            ) {
              vehicleType.name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].translations[j].name
            }
          }
          allTypes.push(vehicleType)
        }

        if (vehicleTypes.data[0].vehicle_types[i] !== null) {
          for (
            let j = 0;
            j < vehicleTypes.data[0].vehicle_types[i].translations.length;
            j++
          ) {
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === 'en'
            ) {
              if (
                app.$slugify(
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                ) === params.type
              ) {
                // eslint-disable-next-line standard/computed-property-even-spacing
                vehicleTypes.data[0].vehicle_types[i].fallback_name =
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                activeType = vehicleTypes.data[0].vehicle_types[i]
              }
            }
            if (
              vehicleTypes.data[0].vehicle_types[i].translations[j].language
                .language_code === store.state.language
            ) {
              if (
                app.$slugify(
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                ) === params.type
              ) {
                vehicleTypes.data[0].vehicle_types[i].name =
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  vehicleTypes.data[0].vehicle_types[i].translations[j].name
                activeType = vehicleTypes.data[0].vehicle_types[i]
              }
            }
          }
        }
      }

      const allModels = []
      const [models] = await Promise.all([
        portal.get(
          `vehicle-types/${activeType.id}?lang=${store.state.language}`
        )
      ])
      for (let i = 0; i < models.data[0].vehicles.length; i++) {
        for (
          let j = 0;
          j < models.data[0].vehicles[i].translations.length;
          j++
        ) {
          const model = models.data[0].vehicles[i]

          for (
            let j = 0;
            j < models.data[0].vehicles[i].translations.length;
            j++
          ) {
            if (
              models.data[0].vehicles[i].translations[j].language
                .language_code === 'en'
            ) {
              model.fallback_name =
                models.data[0].vehicles[i].translations[j].name
            }
            if (
              models.data[0].vehicles[i].translations[j].language
                .language_code === store.state.language
            ) {
              model.name = models.data[0].vehicles[i].translations[j].name
            }
          }
          allModels.push(model)

          if (
            models.data[0].vehicles[i].translations[j].language
              .language_code === 'en'
          ) {
            if (
              app.$slugify(models.data[0].vehicles[i].translations[j].name) ===
              params.model
            ) {
              // eslint-disable-next-line standard/computed-property-even-spacing
              models.data[0].vehicles[i].fallback_name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                models.data[0].vehicles[i].translations[j].name
              activeModel = models.data[0].vehicles[i]
            }
          }
          if (
            models.data[0].vehicles[i].translations[j].language
              .language_code === store.state.language
          ) {
            if (
              app.$slugify(models.data[0].vehicles[i].translations[j].name) ===
              params.model
            ) {
              // eslint-disable-next-line standard/computed-property-even-spacing
              models.data[0].vehicles[i].name =
                // eslint-disable-next-line standard/computed-property-even-spacing
                models.data[0].vehicles[i].translations[j].name
              activeModel = models.data[0].vehicles[i]
            }
          }
        }
      }

      const productId = params.product.split('-')[0]

      let vehicle = ''
      if (typeof activeModel !== 'undefined') {
        vehicle = '&vehicle=' + activeModel.id
      }
      const [productDetail] = await Promise.all([
        portal.get(`products/${productId}?lang=${store.state.language}${vehicle}`)
      ])

      const product = productDetail.data
      for (let j = 0; j < product.translations.length; j++) {
        if (product.translations[j].language.language_code === 'en') {
          product.fallback_product = product.translations[j]
        }
        if (
          product.translations[j].language.language_code ===
          store.state.language
        ) {
          product.product = product.translations[j]
        }
      }
      activePart = product
      activePart.link =
        product.id + ' ' + product.sku + ' ' + product.product.name

      const alternativeProducts = []
      const spareProducts = []
      if (activePart.product_alternatives.length > 0) {
        for (let i = 0; i < activePart.product_alternatives.length; i++) {
          const product = activePart.product_alternatives[i]

          for (
            let j = 0;
            j < activePart.product_alternatives[i].translations.length;
            j++
          ) {
            if (
              activePart.product_alternatives[i].translations[j].language
                .language_code === 'en'
            ) {
              product.fallback_product =
                activePart.product_alternatives[i].translations[j]
            }
            if (
              activePart.product_alternatives[i].translations[j].language
                .language_code === store.state.language
            ) {
              product.product =
                activePart.product_alternatives[i].translations[j]
            }
          }
          if(product.product !== undefined) {
            product.link = product.id + ' ' + product.sku + ' ' + product.product.name
            alternativeProducts.push(product)
          }
        }
      }
      if (activePart.product_spareparts.length > 0) {
        for (let i = 0; i < activePart.product_spareparts.length; i++) {
          const product = activePart.product_spareparts[i]

          for (
            let j = 0;
            j < activePart.product_spareparts[i].translations.length;
            j++
          ) {
            if (
              activePart.product_spareparts[i].translations[j].language
                .language_code === 'en'
            ) {
              product.fallback_product =
                activePart.product_spareparts[i].translations[j]
            }
            if (
              activePart.product_spareparts[i].translations[j].language
                .language_code === store.state.language
            ) {
              product.product = activePart.product_spareparts[i].translations[j]
            }
          }
          if(product.product !== undefined){
            product.link = product.id + ' ' + product.sku + ' ' + product.product.name;
            spareProducts.push(product)
          }
        }
      }

      const breadcrumbs = [
        {
          name: app.i18n.t('menu.home'),
          url: 'index'
        },
        {
          name: app.i18n.t('menu.products'),
          url: 'products'
        },
        {
          name:
            activeGroup.name !== ''
              ? activeGroup.name
              : activeGroup.fallback_name,
          url: {
            name: 'products-group',
            params: {
              category: app.$slugify(activeGroup.name)
            }
          }
        },
        {
          name: activeBrand.name,
          url: {
            name: 'products-group-brand',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name)
            }
          }
        },
        {
          name: activeType.name,
          url: {
            name: 'products-group-brand-type',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name),
              type: app.$slugify(activeType.name)
            }
          }
        },
        {
          name: activeModel.name ?? activeModel.fallback_name,
          url: {
            name: 'products-group-brand-type-model',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name),
              type: app.$slugify(activeType.name),
              model: app.$slugify(activeModel.name ?? activeModel.fallback_name)
            }
          }
        },
        {
          name: activePart.product.name,
          url: {
            name: 'products-group-brand-type-model-product',
            params: {
              category: app.$slugify(activeGroup.name),
              brand: app.$slugify(activeBrand.name),
              type: app.$slugify(activeType.name),
              model: app.$slugify(
                activeModel.name ?? activeModel.fallback_name
              ),
              product: app.$slugify(activePart.link)
            }
          }
        }
      ]
      let description = activePart.product.description
      if (activePart.product.part_specification_1) {
        description =
          description + ' \n ' + activePart.product.part_specification_1
      }
      if (activePart.product.part_specification_2) {
        description =
          description + ' \n ' + activePart.product.part_specification_2
      }
      if (activePart.product.part_specification_3) {
        description =
          description + ' \n ' + activePart.product.part_specification_3
      }


      // description = description + activePart.oems.join(', ')

      return {
        breadcrumbs,
        activeGroup,
        activeBrand,
        activeType,
        activeModel,
        activePart,
        allBrands,
        allTypes,
        allModels,
        productDetail: productDetail.data,
        spareProducts,
        alternativeProducts,
        structuredData: {
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: activePart.product.name,
          image:
            activePart.images ??
            'https://www.apairltd.com/images/placeholder.jpg',
          description,
          sku: activePart.to_be_announced ? 'TBA' : activePart.sku,
          mpn: activePart.to_be_announced ? 'TBA' : activePart.sku,
          brand: {
            '@type': 'Brand',
            name: 'AP Air LTD.'
          },
          offers: {
            '@type': 'Offer',
            availability: 'https://schema.org/InStock',
            price:
              activePart.price_object === null
                ? 0
                : JSON.parse(activePart.price_object.price_object).Pricelist16,
            priceCurrency: 'GBP'
          }
        }
      }
    } catch {
      app.context.redirect('/')
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'Product',
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
